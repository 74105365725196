@tailwind base;
@tailwind components;
@tailwind utilities;

/*
:root {
  --color-primary: #ff9e0c;
  --color-secondary: #ecc94b;
}
*/

html:before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  z-index: -5;
  background: linear-gradient(180deg,rgba(50,70,80,.9) 0,#0d101b 100%);
}

html:after {
  content: "";
  position: fixed;
  top: 0;
  height: 100%;
  left: 0;
  right: 0;
  z-index: -10;
  background-color: #273136;
  background-image: linear-gradient(180deg,rgba(50,70,80,.9) 0,#0d101b 100%);
  background-image: url(assets/img/cover.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: initial;
  height: 100%;
  transition: background .2s linear;
  background-size: cover;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body:before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background-attachment: initial;
  background-color: transparent;
  background-image: url(assets/img/pattern.png);
  background-size: 4.6875rem;
  background-repeat: repeat;
  background-attachment: initial;
  z-index: -5;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/*
.bg-primary {
  --tw-bg-opacity: 1;
  background-color: rgb(255, 159, 12/var(--tw-bg-opacity));
}
*/