@charset "UTF-8";@import url(https://fonts.googleapis.com/css2?family=Chakra+Petch:wght@300;500&display=swap);

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

body {
  font-family: Chakra Petch, sans-serif;
  color: rgb(255 255 255 / 75%);
}

.app-content {
  padding: 2rem 2rem;
  flex: 1;
  /*margin-left: 16.875rem;*/
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.page-header {
  color: #fff;
  padding: 0;
  border: none;
  margin-bottom: 1rem;
  font-weight: 500;
  font-size: 1.75rem;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0 0;
  margin-bottom: 1rem;
  font-size: .7875rem;
  list-style: none;
  font-weight: 500;
}

.breadcrumb a:hover {
  text-decoration: underline;
}

.breadcrumb-item.active {
  color: #fff;
}

.breadcrumb-item+.breadcrumb-item {
  padding-left: 0.5rem;
}

.app-header {
  height: 3.25rem;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1020;
  background: rgba(29,40,53,.95);
  display: flex;
  transition: all .15s ease-in-out;
}

.app-header .desktop-toggler {
  height: 100%;
  display: flex;
  align-items: center;
  margin-right: auto;
}

.app-header .mobile-toggler {
  height: 100%;
  display: none;
  margin-right: auto;
}

.app-header .brand {
  height: 100%;
  width: 18.875rem;
  padding: 0 1rem;
  display: flex;
  align-items: center;
}

.app-header .menu {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
}

.app-header .menu .menu-item {
  height: 100%;
  display: flex;
  align-items: center;
}

.dropdown, .dropend, .dropstart, .dropup {
  position: relative;
}

.app-header .menu .menu-item .menu-link {
  text-decoration: none;
  color: #fff;
  position: relative;
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
}

.dropdown-menu {
  box-shadow: 0 0.5rem 1rem rgba(255,255,255,.075);
}

.app-header .menu .menu-item .menu-link .menu-img {
  width: 2rem;
  height: 2rem;
  margin-right: 0.75rem;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1055;
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.card {
  --bs-border-color: #FFFFFF;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: none;
  background-clip: border-box;
  border: 1px solid rgba(255,255,255,.3);
  border-radius: 0;
}

.card:after, .card:before {
  content: "";
  position: absolute;
}

.card:not(.border-0):before {
  left: 15px;
  right: 15px;
  top: 0;
  bottom: 0;
  opacity: .3;
}

.card .card-arrow {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.card .card-arrow-bottom-left, .card .card-arrow-top-left {
  left: 0;
}

.card .card-arrow-bottom-left, .card .card-arrow-top-left {
  left: 0;
}

.card .card-arrow-bottom-left, .card .card-arrow-bottom-right {
  bottom: 0;
}

.card .card-arrow-bottom-left:after, .card .card-arrow-bottom-left:before, .card .card-arrow-top-left:after, .card .card-arrow-top-left:before {
  left: 0;
}

.card>:not(.card-arrow):not(.card-img-overlay):not(.card-img):not(.hljs-container) {
  position: relative;
  z-index: 10;
}

.card .card-arrow-bottom-right, .card .card-arrow-top-right {
  right: 0;
}

.card .card-arrow-top-left, .card .card-arrow-top-right {
  top: 0;
}

.card .card-arrow-bottom-left:before, .card .card-arrow-bottom-right:before, .card .card-arrow-top-left:before, .card .card-arrow-top-right:before {
  content: "";
  position: absolute;
  width: 2px;
  height: 8px;
  background: var(--bs-border-color);
  opacity: .75;
}

.card .card-arrow-bottom-left:after, .card .card-arrow-bottom-right:after, .card .card-arrow-top-left:after, .card .card-arrow-top-right:after {
  content: "";
  position: absolute;
  width: 10px;
  height: 2px;
  background: var(--bs-border-color);
  opacity: .75;
}

.card-body {
  flex: 1 1 auto;
  padding: 1rem 1rem
}

.card-header {
  padding: 0.55rem 1rem;
  margin-bottom: 0;
  background-color: none;
  border-bottom: 1px solid rgba(255,255,255,.3);
}

.card-header:first-child {
  border-radius: 0;
}

a {
  color: #ff9f0c;
}
.btn-outline-theme {
  color: #ff9f0c;
  border-color: #ff9f0c;
}

.btn {
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  color: rgba(255,255,255,.75);
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: .875rem;
  border-radius: 4px;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.btn-group-sm>.btn, .btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: .8125rem;
  border-radius: 2px;
}

.btn-outline-theme:hover {
  color: #000;
  background-color: #3cd2a5;
  border-color: #3cd2a5;
}

.btn-outline-theme.active, .btn-outline-theme:hover {
  background-color: #ff9f0c;
  border-color: #ff9f0c;
}

hr:not([size]) {
  height: 1px;
}

hr {
  margin: 1rem 0;
  color: inherit;
  background-color: currentColor;
  border: 0;
  opacity: .25;
}

.loaderMask {
  background: #00000061;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.border-white-15 {
  border-color: #ffffff2b;
}